<template>
  <div v-if="model">
    <img src="/images/bg/key-point-bg.png" class="news-bg static" alt="Haber" />
    <div class="newsMain">
      <div class="news-title-logo wow fadeInDown" data-wow-delay="0.2s">
        <img src="/images/bg/key-point-logo.png" alt="Logo" />
      </div>

      <div class="news-image wow fadeInUp" data-wow-delay="1.4s">
        <div class="news-image-description">
          <span v-html="model.description"></span>
        </div>
        <img :src="model.fileName" alt="İçerik" />
      </div>
    </div>
  </div>
</template>

<script>
import WOW from "wow.js";
import store from "../store/index";
new WOW().init();
export default {
  name: "key-point",
  props: ["data"],
  created() {
    this.startTimer();
  },
  data() {
    return {
      destroyed: false
    };
  },
  methods: {
    startTimer() {
      setTimeout(() => {
        if (!this.destroyed) store.dispatch("setNextPlaylist");
      }, this.model.displaySecond * 1000);
    }
  },
  computed: {
    model() {
      return this.$store.state.backgroundImage;
    }
  },
  beforeDestroy() {
    this.destroyed = true;
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/animate/animate.css";
@import "../assets/scss/news.scss";

.newsMain {
  top: 8px !important;
  height: 100% !important;

  .news-title-logo {
    position: relative;
    z-index: 999;
  }

  .news-image {
    margin: 30px auto 0;
    border: solid 5px #3a4c56;

    img {
      background-color: rgba(#3a4c56, 0.8);
      object-fit: cover;
    }

    max-width: 75% !important;
  }

  .news-image-description {
    position: absolute;
    left: -5px;
    right: -5px;
    top: -80px;
    border: solid 5px #3a4c56;
    border-radius: 0 0 56px 56px;
    background: url("/images/bg/key-point-title-bg.png") center top no-repeat #f4f1eb;
    background-size: cover;
    padding: 42px 26px 24px;
    color: #3a4c56;
    font-size: 30px;
    line-height: 1.2;
    -webkit-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.52);
    -moz-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.52);
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.52);
  }
}</style>
